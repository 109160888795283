import React, {useMemo} from "react";
import ReactDOM from "react-dom/client";
import {useApplicationCore} from "src/lib/application/ApplicationCore";
import {ApplicationSetting} from "src/lib/application/ApplicationSetting";
import {ApplicationPropsContextProvider} from "src/lib/application/context/application-props/ApplicationPropsContext";
import {useApplicationProps} from "src/lib/application/context/application-props/ApplicationPropsHooks";
import {ApplicationAuthContextProvider} from "src/lib/application/context/auth/ApplicationAuthContext";
import {ApplicationLanguageContextProvider} from "src/lib/application/context/language/ApplicationLanguageContext";
import {ApplicationLoaderContextProvider} from "src/lib/application/context/loader/ApplicationLoaderContext";
import {ApplicationThemeContextProvider} from "src/lib/application/context/theme/ApplicationThemeContext";
import {ApplicationComponentRender} from "src/lib/application/render/ApplicationComponentRender";

export function ApplicationWrapper() {
    const {setting} = useApplicationProps();
    const applicationCoreState = useApplicationCore();
    return useMemo(() => {
        if (!applicationCoreState.renderApplicationReady) {
            return <setting.LoadingComponent/>;
        }
        return (
            <ApplicationComponentRender/>
        );
        // eslint-disable-next-line
    }, [applicationCoreState.renderApplicationReady, setting, setting.LoadingComponent]);
}

export function Application(props: { setting: ApplicationSetting }) {
    return (
        <ApplicationPropsContextProvider setting={props.setting}>
            <ApplicationLoaderContextProvider>
                <ApplicationAuthContextProvider>
                    <ApplicationLanguageContextProvider>
                        <ApplicationThemeContextProvider>
                            <ApplicationWrapper/>
                        </ApplicationThemeContextProvider>
                    </ApplicationLanguageContextProvider>
                </ApplicationAuthContextProvider>
            </ApplicationLoaderContextProvider>
        </ApplicationPropsContextProvider>
    );
}

export function runApplication(setting: ApplicationSetting) {
    const root = ReactDOM.createRoot(
        document.getElementById('root') as HTMLElement
    );
    const RenderComponent = setting.strictMode ? React.StrictMode : React.Fragment;
    root.render(
        <RenderComponent>
            <Application setting={setting}/>
        </RenderComponent>
    );
}