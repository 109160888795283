import {useEffect, useState} from "react";
import {ApplicationCoreState} from "src/lib/application/ApplicationSetting";
import {useApplicationProps} from "src/lib/application/context/application-props/ApplicationPropsHooks";
import {useApplicationAuthUser} from "src/lib/application/context/auth/ApplicationAuthHooks";
import {useLanguageCore} from "src/lib/application/context/language/ApplicationLanguageHooks";
import {useThemeCore} from "src/lib/application/context/theme/ApplicationThemeHooks";


export function useApplicationCore(): ApplicationCoreState {
    // TODO implement this const beforeDestroy = settings?.useBeforeDestroy?.();
    const {setting} = useApplicationProps();
    const beforeLoad = setting.useBeforeLoad();
    const applicationProps = useApplicationProps();

    const applicationAuth = useApplicationAuthUser();
    const applicationLanguage = useLanguageCore();
    const applicationTheme = useThemeCore();

    const [loadUserReady, setLoadUserReady] = useState<boolean>(false);
    const [loadLanguageReady, setLoadLanguageReady] = useState<boolean>(false);
    const [loadThemeReady, setLoadThemeReady] = useState<boolean>(false);

    useEffect(() => {
        // when enable auto ready on user auth, it will set flag true, other if not it will not set it
        if (applicationAuth.userLoaded || !setting.autoReady.auth) {
            setLoadUserReady(true);
        }
        if (applicationLanguage.loaded || !setting.autoReady.language) {
            setLoadLanguageReady(true);
        }
        if (applicationTheme.loaded || !setting.autoReady.theme) {
            setLoadThemeReady(true);
        }
    }, [setting.autoReady, applicationAuth.userLoaded, applicationLanguage.loaded, applicationTheme.loaded]);

    useEffect(() => {
        // auto make application ready when all flags enabled or not autoReady
        if (loadUserReady && loadLanguageReady && loadThemeReady) {
            applicationProps.makeApplicationReady();
        }
        // eslint-disable-next-line
    }, [loadUserReady, loadLanguageReady, loadThemeReady, applicationProps.makeApplicationReady]);
    return {
        renderApplicationReady: applicationProps.ready && beforeLoad.ready
    };
}

