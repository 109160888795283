import {Route} from "src/lib/routes/Route";

export const ROUTE_SETTINGS: Route[] = [
    {
        path: '',
        component: () =>{
            return (
                <div>
                    This home page
                </div>
            )
        },
        auth: 'auth'
    },
    {
        path: '/login',
        component: () =>{
            return (
                <div>
                    This login page
                </div>
            )
        },
        auth: 'auth'
    }
]