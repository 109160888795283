import {useContext} from "react";
import {ApplicationThemeContext} from "src/lib/application/context/theme/ApplicationThemeContext";
import {Color, ThemeProfile} from "src/lib/application/context/theme/ApplicationThemeModels";

export function useColor<C extends Color>(): C {
    const themeContextState = useContext(ApplicationThemeContext);
    return themeContextState.color as C;
}

export function useThemeProfiles<C extends Color>(): ThemeProfile<C>[] {
    const themeContextState = useContext(ApplicationThemeContext);
    return themeContextState.profiles as ThemeProfile<C>[];
}

export function useThemeCore<C extends Color>(): {
    selectTheme: (theme: string) => void,
    selectedProfile: ThemeProfile<C>,
    profiles: ThemeProfile<C>[],
    loaded: boolean
} {
    const themeContextState = useContext(ApplicationThemeContext);
    return {
        selectTheme: themeContextState.selectTheme,
        selectedProfile: themeContextState.selectedProfile as ThemeProfile<C>,
        profiles: themeContextState.profiles as ThemeProfile<C>[],
        loaded: themeContextState.loaded
    }
}