import {createContext, useCallback, useState} from "react";
import {UserBase} from "src/lib/models/User";
import {BaseRequest} from "src/lib/services/http/BaseRequest";

export interface ApplicationAuthContextState {
    token: string | null;
    user: UserBase | null;
    permissions: string[];
    userLoaded: boolean;
    tokenLoaded: boolean;
    isAuthenticate: boolean;
    setUser: (user: UserBase) => void;
    setToken: (token: string | null) => void;
}

export const ApplicationAuthContext = createContext<ApplicationAuthContextState>({
    token: null,
    user: null,
    permissions: [],
    userLoaded: false,
    tokenLoaded: false,
    isAuthenticate: false,
    setToken: (token) => {
    },
    setUser: (user) => {
    }
});

export function ApplicationAuthContextProvider(props: { children: any }) {
    const [token, setTokenState] = useState<string | null>(null);
    const [tokenLoaded, setTokenLoaded] = useState<boolean>(false);
    const [userLoaded, setUserLoaded] = useState<boolean>(false);
    const [isAuthenticate, setAuthenticate] = useState<boolean>(false);
    const [user, setUserState] = useState<UserBase | null>(null);
    const [permissions, setPermissions] = useState<string[]>([]);
    const setToken = useCallback((token: string | null) => {
        setTokenLoaded(true);
        setTokenState(token);
        BaseRequest.sharedToken = token;
    }, []);
    const setUser = useCallback((user: UserBase | null) => {
        setUserLoaded(true);
        setUserState(user);
        setPermissions(user?.permission ?? []);
        setAuthenticate(!!user && !!user.email);
    }, []);
    return (
        <ApplicationAuthContext.Provider
            value={{
                token,
                user,
                permissions,
                tokenLoaded,
                userLoaded,
                isAuthenticate,
                setUser,
                setToken
            }}
        >
            {props.children}
        </ApplicationAuthContext.Provider>
    );
}