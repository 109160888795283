import {useContext, useMemo} from "react";
import {ApplicationSetting} from "src/lib/application/ApplicationSetting";
import {ApplicationPropsContext} from "src/lib/application/context/application-props/ApplicationPropsContext";

export interface ApplicationPropsState {
    ready: boolean;
    makeApplicationReady: () => void;
    setting: ApplicationSetting;
}

export function useApplicationProps(): ApplicationPropsState {
    const applicationPropsContext = useContext(ApplicationPropsContext);

    return useMemo(() => ({
        ready: applicationPropsContext.ready,
        makeApplicationReady: applicationPropsContext.applicationReady,
        setting: applicationPropsContext.setting
    }), [
        applicationPropsContext.ready,
        applicationPropsContext.applicationReady,
        applicationPropsContext.setting
    ]);
}