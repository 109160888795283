import {BaseRequest} from "src/lib/services/http/BaseRequest";

export class HttpBaseService {

    public baseUrl: string;

    public constructor(baseUrl: string) {
        this.baseUrl = baseUrl;
    }

    public builder<T>() {
        return new BaseRequest<T>().baseUrl(this.baseUrl);
    }

}