import {useBeforeApplicationLoad} from "src/app/handlers/BeforeApplicationLoad";
import {onLanguageChange} from "src/app/handlers/onLanguageChange";
import {onThemeChange} from "src/app/handlers/onThemeChange";
import {LANGUAGE_PROFILES} from "src/app/language/LanguageProfiles";
import {
    InitApplicationLoadingComponent
} from "src/app/app-config-components/init-loading-page/InitApplicationLoadingComponent";
import {LazyLoadingComponent} from "src/app/app-config-components/lazy-loading-page/LazyLoadingComponent";
import {NotFoundComponent} from "src/app/app-config-components/not-found-page/NotFoundComponent";
import {WrapperComponent} from "src/app/app-config-components/wrapper-component/WrapperComponent";
import {ROUTE_SETTINGS} from "src/app/routes/Routes";
import {THEME_PROFILES} from "src/app/theme/ThemeProfiles";
import {runApplication} from "src/lib/application/Application";
import reportWebVitals from "src/reportWebVitals";
import './index.css';

runApplication({
    themeProfiles: THEME_PROFILES,
    languageProfiles: LANGUAGE_PROFILES,
    routes: ROUTE_SETTINGS,
    useBeforeLoad: useBeforeApplicationLoad,
    onLanguageChange: onLanguageChange,
    onThemeChange: onThemeChange,
    strictMode: false,
    autoReady: {
        language: true,
        auth: true,
        theme: true
    },
    WrapperComponent: WrapperComponent,
    LoadingComponent: InitApplicationLoadingComponent,
    LazyLoadingComponent: LazyLoadingComponent,
    NotFoundComponent: NotFoundComponent,
    backendURL: 'https://rnd-subdomain-e108faff4746.herokuapp.com/'
});

reportWebVitals(console.log);
