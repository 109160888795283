import {ApplicationColor} from "src/app/theme/colors/ColorType";
import {DarkColors} from "src/app/theme/colors/Dark";
import {LightColors} from "src/app/theme/colors/Light";
import {ThemeProfile} from "src/lib/application/context/theme/ApplicationThemeModels";


export const THEME_PROFILES: ThemeProfile<ApplicationColor>[] = [
    {
        name: 'Light',
        color: LightColors,
        font: {
            size: '1rem',
            family: 'sans-serif'
        }
    },
    {
        name: 'Dark',
        color: DarkColors,
        font: {
            size: '1rem',
            family: 'sans-serif'
        }
    }
]