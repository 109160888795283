import axios, {AxiosError} from "axios";
import {APIError} from "src/lib/services/http/APIError";

export type RequestType = 'multipart/form-data' | 'application/json';

export class BaseRequest<E> {

    public static sharedToken: string | null;
    public static sharedBackendUrl: string;

    private _data: any;
    private _requestType: RequestType = 'application/json';
    private _authenticate: boolean = true;
    private _baseUrl?: string;
    private _timeout?: number;
    private _headers: any;
    private _extraHeaders: any;
    private _path?: string;
    private _responseType?: string;
    private _token?: string;
    private _backendUrl?: string;

    public addHeader(key: string, value: any): BaseRequest<E> {
        if (!this._extraHeaders) {
            this._extraHeaders = {};
        }
        this._extraHeaders[key] = value;
        return this;
    }

    public path(path: string): BaseRequest<E> {
        this._path = path;
        return this;
    }

    public responseType(responseType: string): BaseRequest<E> {
        this._responseType = responseType;
        return this;
    }

    public token(token: string): BaseRequest<E> {
        this._token = token;
        return this;
    }

    public baseUrl(value: string): BaseRequest<E> {
        this._baseUrl = value;
        return this;
    }

    public headers(value: any): BaseRequest<E> {
        this._headers = value;
        return this;
    }

    public backendUrl(value: string): BaseRequest<E> {
        this._backendUrl = value;
        return this;
    }

    public timeout(value: number): BaseRequest<E> {
        this._timeout = value;
        return this;
    }

    public data(data: any): BaseRequest<E> {
        this._data = data;
        return this;
    }

    public requestType(requestType: RequestType): BaseRequest<E> {
        this._requestType = requestType;
        return this;
    }

    public authenticate(authenticate: boolean): BaseRequest<E> {
        this._authenticate = authenticate;
        return this;
    }

    private getHeader = (): any => {
        const headers = this._headers ?? {};
        if (!headers?.['Authorization']) {
            if (this._authenticate) {
                const token = this.getToken();
                if (token && token !== 'null') {
                    headers['Authorization'] = 'Bearer ' + token;
                }
            }
        }
        headers['Content-Type'] = headers['Content-Type'] ?? this._requestType;
        headers['Access-Control-Allow-Origin'] = headers['Access-Control-Allow-Origin'] ?? '*';
        if (this._extraHeaders) {
            Object.keys(this._extraHeaders).forEach(headerName => {
                headers[headerName] = this._extraHeaders[headerName];
            });
        }
        return headers;
    }

    public getToken = () => {
        return this._token ? this._token : (BaseRequest.sharedToken ? BaseRequest.sharedToken : null);
    }

    public getBackendURL() {
        return this._backendUrl ? this._backendUrl : BaseRequest.sharedBackendUrl
    }

    public get(): Promise<E | void> {
        const object: any = {
            baseURL: this.getBackendURL(),
            headers: this.getHeader(),
            timeout: this._timeout
        };
        console.log(object, this.getPath())
        if (this._responseType) {
            object.responseType = this._responseType;
        }
        return axios.create(object).get(this.getPath())
            .then(response => response?.data as E)
            .catch((e: AxiosError) => {
                console.error(e);
                throw new APIError(e);
            });
    }

    public post(): Promise<E | void> {
        return axios.create({
            baseURL: this.getBackendURL(),
            headers: this.getHeader(),
            timeout: this._timeout
        }).post(this.getPath(), this._data)
            .then(response => response?.data as E)
            .catch(e => {
                console.error(e);
                throw new APIError(e);
            });
    }

    public put(): Promise<E | void> {
        return axios.create({
            baseURL: this.getBackendURL(),
            headers: this.getHeader(),
            timeout: this._timeout
        }).put(this.getPath(), this._data).then(response => response?.data as E)
            .catch(e => {
                console.error(e);
                throw new APIError(e);
            });
    }

    public delete(): Promise<E | void> {
        return axios.create({
            baseURL: this.getBackendURL(),
            headers: this.getHeader(),
            timeout: this._timeout
        }).delete(this.getPath()).then(response => response?.data as E)
            .catch(e => {
                console.error(e);
                throw new APIError(e);
            });
    }

    private getPath(): string {
        return (this._baseUrl ?? '') + (this._path ?? '');
    }
}