import {useCallback, useContext} from "react";
import {ApplicationLanguageContext} from "src/lib/application/context/language/ApplicationLanguageContext";
import {LanguageProfile, Word} from "src/lib/application/context/language/ApplicationLanguageModels";

export function useWord<L extends Word>(): L {
    const languageProfile = useContext(ApplicationLanguageContext);
    return languageProfile.word as L;
}

export function useGetWord(): (data: { [flag: string]: string }) => string {
    const languageProfile = useContext(ApplicationLanguageContext);
    return useCallback((data: { [flag: string]: string }) => {
        return data[languageProfile.flag];
    }, [languageProfile.flag]);
}

export function useLanguageProfiles<L extends Word>(): LanguageProfile<L>[] {
    const languageProfile = useContext(ApplicationLanguageContext);
    return languageProfile.profiles as LanguageProfile<any>[];
}

export function useLanguageCore<L extends Word>(): {
    selectFlag: (flag: string) => void,
    selectedProfile: LanguageProfile<L>,
    profiles: LanguageProfile<L>[],
    loaded: boolean
} {
    const languageProfile = useContext(ApplicationLanguageContext);
    return {
        selectFlag: languageProfile.selectFlag,
        selectedProfile: languageProfile.selectedProfile as LanguageProfile<L>,
        profiles: languageProfile.profiles as LanguageProfile<L>[],
        loaded: languageProfile.loaded
    }
}