import {HttpBaseService} from "src/lib/services/http/HttpBaseService";

class ShopHttpServiceC extends HttpBaseService {

    public constructor() {
        super("/api/v0/shop");
    }

    public getShopInfo(shopKey: string): Promise<any> {
        return this.builder<any>()
            .path(`/${shopKey}`)
            .get();
    }

}

export const ShopHttpService = new ShopHttpServiceC();