import {useCallback, useContext, useMemo} from "react";
import {ApplicationAuthContext} from "src/lib/application/context/auth/ApplicationAuthContext";
import {UserBase} from "src/lib/models/User";

export interface ApplicationAuthUserState<U extends UserBase> {
    user: U | null;
    setUser: (user: U | null) => void;
    userLoaded: boolean;
    isAuthenticate: boolean;
    permissions: string[];
}

export interface ApplicationAuthTokenState {
    token: string | null;
    clearToken: () => void;
    tokenLoaded: boolean;
    setToken: (token: string) => void;
}

export function useApplicationAuthUser<U extends UserBase>(): ApplicationAuthUserState<U> {
    const applicationAuthContext = useContext(ApplicationAuthContext);

    return useMemo(() => ({
        user: applicationAuthContext.user as U,
        setUser: applicationAuthContext.setUser as any,
        userLoaded: applicationAuthContext.userLoaded,
        isAuthenticate: applicationAuthContext.isAuthenticate,
        permissions: applicationAuthContext.permissions
    }), [
        applicationAuthContext.isAuthenticate,
        applicationAuthContext.setUser,
        applicationAuthContext.user,
        applicationAuthContext.userLoaded,
        applicationAuthContext.permissions
    ]);
}

export function useApplicationAuthToken(): ApplicationAuthTokenState {
    const applicationAuthContext = useContext(ApplicationAuthContext);
    const clearToken = useCallback(() => {
        applicationAuthContext.setToken(null);
    }, [applicationAuthContext]);
    return useMemo(() => ({
        token: applicationAuthContext.token,
        setToken: applicationAuthContext.setToken,
        tokenLoaded: applicationAuthContext.tokenLoaded,
        clearToken
    }), [
        applicationAuthContext.setToken,
        applicationAuthContext.token,
        applicationAuthContext.tokenLoaded,
        clearToken
    ]);
}