import {createContext, useCallback, useEffect, useState} from "react";
import {ApplicationSetting} from "src/lib/application/ApplicationSetting";
import {BaseRequest} from "src/lib/services/http/BaseRequest";

export interface ApplicationPropsContextState {
    ready: boolean;
    applicationReady: () => void;
    setting: ApplicationSetting;
}

// @ts-ignore
export const ApplicationPropsContext = createContext<ApplicationPropsContextState>();

export function ApplicationPropsContextProvider(props: { children: any, setting: ApplicationSetting }) {
    const [ready, setReady] = useState(false);
    const applicationReady = useCallback(() => {
        setReady(true);
    }, []);
    BaseRequest.sharedBackendUrl = props.setting.backendURL;
    useEffect(() => {
        BaseRequest.sharedBackendUrl = props.setting.backendURL;
    }, [props.setting.backendURL]);
    return (
        <ApplicationPropsContext.Provider value={{
            ready,
            setting: props.setting,
            applicationReady
        }}>
            {props.children}
        </ApplicationPropsContext.Provider>
    );
}