import {createContext, useCallback, useState} from "react";
import {useApplicationProps} from "src/lib/application/context/application-props/ApplicationPropsHooks";
import {LanguageProfile, Word} from "src/lib/application/context/language/ApplicationLanguageModels";



export interface ApplicationLanguageContextState {
    loaded: boolean;
    profiles: LanguageProfile<Word>[];
    selectedProfile: LanguageProfile<Word>;
    selectFlag: (flag: string) => void;
    word: Word;
    flag: string;
}

// @ts-ignore
export const ApplicationLanguageContext = createContext<ApplicationLanguageContextState>();

export function ApplicationLanguageContextProvider(props: { children: any }) {
    const {setting} = useApplicationProps();
    const [selectedProfile, setSelectedProfile] = useState<LanguageProfile<any>>(setting.languageProfiles?.[0]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const selectFlag = useCallback((flag: string) =>{
        const profile = setting.languageProfiles.find((p: any) => p.flag === flag);
        if (profile) {
            setting.onLanguageChange(profile);
            setSelectedProfile(profile);
            setLoaded(true);
        } else {
            console.error(`Language profile ${flag} not found`);
        }
        // eslint-disable-next-line
    }, [setting.languageProfiles, setting.onLanguageChange]);

    return (
        <ApplicationLanguageContext.Provider value={{
            loaded,
            selectFlag,
            selectedProfile,
            profiles: setting.languageProfiles,
            word: selectedProfile?.word,
            flag: selectedProfile?.flag
        }}>
            {props.children}
        </ApplicationLanguageContext.Provider>
    );
}
