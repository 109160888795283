import {createContext, useCallback, useMemo, useState} from "react";
import {useApplicationProps} from "src/lib/application/context/application-props/ApplicationPropsHooks";
import {Color, ThemeProfile} from "src/lib/application/context/theme/ApplicationThemeModels";


export interface ApplicationThemeContextState {
    loaded: boolean;
    profiles: ThemeProfile<Color>[];
    selectedProfile: ThemeProfile<Color>;
    selectTheme: (flag: string) => void;
    color: Color;
    font: {
        size: string;
        family: string;
    }
}

// @ts-ignore
export const ApplicationThemeContext = createContext<ApplicationThemeContextState>();

export function ApplicationThemeContextProvider(props: { children: any }) {
    const {setting} = useApplicationProps();
    const [selectedProfile, setSelectedProfile] = useState<ThemeProfile<any>>(setting.themeProfiles?.[0]);
    const [loaded, setLoaded] = useState<boolean>(false);
    const selectTheme = useCallback((name: string) => {
        const profile = setting.themeProfiles.find(p => p.name === name);
        if (profile) {
            setting.onThemeChange(profile);
            setSelectedProfile(profile);
            setLoaded(true);
        } else {
            console.error(`Theme profile ${name} not found`);
        }
        // eslint-disable-next-line
    }, [setting.languageProfiles, setting.onLanguageChange]);

    return (
        <ApplicationThemeContext.Provider value={useMemo(() => ({
            loaded,
            selectTheme,
            selectedProfile,
            profiles: setting.themeProfiles,
            color: selectedProfile?.color,
            font: selectedProfile?.font
            // eslint-disable-next-line
        }), [
            loaded,
            selectTheme,
            selectedProfile,
            setting.themeProfiles,
            selectedProfile?.color,
            selectedProfile?.font
        ])}>
            {props.children}
        </ApplicationThemeContext.Provider>
    );
}
