import {createContext} from "react";
import {useLoader} from "src/lib/hooks/loader.hooks";

export interface ApplicationLoaderContextState {
    loading: boolean;
    active: () => void;
    deActive: () => void;
}

export const ApplicationLoaderContext = createContext<ApplicationLoaderContextState>({
    loading: false,
    active: () => {
    },
    deActive: () => {
    }
});

export function ApplicationLoaderContextProvider(props: { children: any }) {
    const loader = useLoader();
    return (
        <ApplicationLoaderContext.Provider value={{
            loading: loader.loading,
            deActive: loader.deActive,
            active: loader.active
        }}>
            {props.children}
        </ApplicationLoaderContext.Provider>
    );
}
