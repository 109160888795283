import {ARABIC_WORD} from "src/app/language/words/Arabic";
import {ENGLISH_WORD} from "src/app/language/words/English";
import {WordType} from "src/app/language/words/WordType";
import {LanguageProfile} from "src/lib/application/context/language/ApplicationLanguageModels";

export const LANGUAGE_PROFILES: LanguageProfile<WordType>[] = [
    {
        name: 'English',
        dir: 'ltr',
        word: ENGLISH_WORD,
        flag: 'en'
    },
    {
        name: 'Arabic',
        dir: 'ltr',
        word: ARABIC_WORD,
        flag: 'ar'
    }
]