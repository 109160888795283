import React, {useMemo} from "react";
import {useApplicationProps} from "src/lib/application/context/application-props/ApplicationPropsHooks";
import {useApplicationAuthUser} from "src/lib/application/context/auth/ApplicationAuthHooks";
import {ApplicationComponentRouter} from "src/lib/application/render/ApplicationComponentRouter";


function nestProviderComponents(components: any[], last: any) {
    if (!components.length) {
        return last;
    }

    const [FirstComponent, ...rest] = components;

    return (
        <FirstComponent>
            {nestProviderComponents(rest, last)}
        </FirstComponent>
    );
}

export function ApplicationComponentRender() {
    const {isAuthenticate} = useApplicationAuthUser();
    const {setting} = useApplicationProps();
    console.log('Application Render NOW ' + isAuthenticate);
    const WrapperComponent = useMemo(() => {
        return setting.WrapperComponent ? setting.WrapperComponent : React.Fragment;
    }, [setting.WrapperComponent]);
    const providerToRender = useMemo(() => {
        if (!setting?.contextProviders) {
            return [];
        }
        if (isAuthenticate) {
            return setting.contextProviders?.filter(route =>
                (route.auth === 'auth' || route.auth === 'both')
            )
        }
        return setting.contextProviders?.filter(route => route.auth === 'unAuth' || route.auth === 'both');
    }, [isAuthenticate, setting.contextProviders]);
    return (
        <>
            {
                setting.TopComponent ? (
                    <setting.TopComponent/>
                ) : null
            }
            {
                nestProviderComponents(
                    providerToRender,
                    <WrapperComponent>
                        <ApplicationComponentRouter/>
                    </WrapperComponent>)
            }
            {
                setting.BottomComponent ? (
                    <setting.BottomComponent/>
                ) : null
            }
        </>
    );
}