import React, {JSX, Suspense, useMemo} from "react";
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {useApplicationProps} from "src/lib/application/context/application-props/ApplicationPropsHooks";
import {useApplicationAuthUser} from "src/lib/application/context/auth/ApplicationAuthHooks";

export function ApplicationComponentRouter() {
    const {setting} = useApplicationProps();
    const {isAuthenticate, permissions} = useApplicationAuthUser();
    const routesSettingToRender = useMemo(() => {
        if (isAuthenticate) {
            return setting.routes.filter(route =>
                (route.auth === 'auth' || route.auth === 'both') &&
                (!route.permission || permissions.includes(route.permission))
            )
        }
        return setting.routes.filter(route => route.auth === 'unAuth' || route.auth === 'both');
    }, [isAuthenticate, permissions, setting.routes]);
    const routesComponentList: JSX.Element[] = useMemo(() => {
        return routesSettingToRender.map(route => {
            return (
                <Route key={route.path} path={route.path} element={<route.component/>}/>
            )
        });
    }, [routesSettingToRender]);
    // @ts-ignore
    return (
        <Router>
            <Suspense fallback={setting.LazyLoadingComponent ? <setting.LazyLoadingComponent/> : <setting.LoadingComponent/>}>
                <Routes>
                    {routesComponentList}
                    <Route path="*" element={<setting.NotFoundComponent />} />
                </Routes>
            </Suspense>
        </Router>
    )
}