import {AxiosError} from "axios";

export class APIError extends Error {
    messageCode?: string;
    message: string;
    statusCode: number;

    constructor(error: AxiosError) {
        super();
        this.messageCode =  (error?.response?.data as any)?.messageCode;
        this.message = (error?.response?.data as any)?.message ?? error?.message;
        this.statusCode = error?.status ?? 0;
    }

    toString(): string {
        return `Error{ statusCode: ${this.statusCode}, messageCode: ${this.messageCode}, message: ${this.message} `
    }
}

