import {useEffect, useState} from "react";
import {ShopHttpService} from "src/app/modules/shop-dashboard/http-services/ShopHttpService";
import {ApplicationEventResponse} from "src/lib/application/ApplicationSetting";
import {useApplicationAuthUser} from "src/lib/application/context/auth/ApplicationAuthHooks";
import {useLanguageCore} from "src/lib/application/context/language/ApplicationLanguageHooks";
import {useThemeCore} from "src/lib/application/context/theme/ApplicationThemeHooks";

export function useBeforeApplicationLoad(): ApplicationEventResponse {
    const [ready, setReady] = useState<boolean>(false);
    const applicationAuth = useApplicationAuthUser();
    const applicationLanguage = useLanguageCore();
    const applicationTheme = useThemeCore();

    useEffect(() => {
        applicationTheme.selectTheme('Light');
        applicationLanguage.selectFlag('en');

        applicationAuth.setUser({
            email: 'development-team@pixelstown',
            permission: []
        });
        const hostname = window.location.hostname;
        const subdomain = hostname.split('.')[0];
        console.log(hostname, subdomain);
        ShopHttpService.getShopInfo(subdomain).then(data => {
            console.log(data);
            setReady(true);
        }, (e) => {
            console.log(e);
            setReady(true);
        });
        // eslint-disable-next-line
    }, [applicationAuth.setUser, applicationLanguage.selectFlag, applicationTheme.selectTheme]);
    return {
        ready
    }
}