import {useCallback, useMemo, useState} from "react";

export interface LoaderValue {
    loading: boolean;
    active: () => void;
    deActive: () => void;
}

export function useLoader(): LoaderValue {
    const [loading, setLoading] = useState<boolean>(false);
    const active = useCallback(() => {
        setLoading(true);
    }, []);
    const deActive = useCallback(() => {
        setLoading(false);
    }, []);
    return useMemo(() => ({
        loading,
        active,
        deActive
    }), [
        active,
        deActive,
        loading
    ]);
}